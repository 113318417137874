import React from "react";
import { privacyPolicy } from "../../utils/mock";
import { Icon } from "../../ui";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>Privacy Policy</h4>
                <p>
                  Welcome to Funzone360! Your privacy is important to us. This
                  Privacy Policy explains how we collect, use, and protect your
                  personal information when you use our website and services.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="commanOther">
              <p>
                We are the flagship brand of {" "}
                <Icon name="CompnayBlack" className="policy" />, offering Our
                Services to You wherein You can also participate in various
                Contests hosted on Our Platform. Any person utilizing the
                Platform or any of its features, including participation in
                various Contests being conducted on the Platform, shall be bound
                by the terms of this privacy policy (“Privacy Policy”).
              </p>
            </div>
            {privacyPolicy.map((section, index) => (
              <div className="commanOther" key={index}>
                <h4>{section.title}</h4>
                <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>
              </div>
            ))}
             <div className="commanOther">
              <Icon name="CompnayBlack" className="policy" />
              <p>
                Address :{" "}
                <a href="https://maps.app.goo.gl/EngYwsBYfvAHiPbG6">
                Shop no. 11, Shri Dadu Dayal Nagar, CD Block, Kalyanpura,
                Sanganer, Jaipur, Rajasthan, 302020
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
