export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Create Your Dream Team",
    description:
      "Choose your favorite players, build a standout team, and showcase your strategic skills in every match.",
  },
  {
    icon: "tradingItemTwo",
    title: "Compete with Friends",
    description:
      "Challenge friends in various leagues, test your sports knowledge, and prove who's the ultimate fantasy sports champion.",
  },
  {
    icon: "tradingItemThree",
    title: "Win Exciting Rewards",
    description:
      "Earn points, rank high, and win amazing prizes by performing exceptionally well in fantasy sports leagues.",
  },
  {
    icon: "tradingItemFour",
    title: "Real-Time Updates",
    description:
      "Stay updated with live scores, player performances, and make informed decisions to stay ahead in the game.",
  },
  {
    icon: "tradingItemFive",
    title: "User-Friendly Interface",
    description:
      "Enjoy a seamless, intuitive experience designed for both beginners and experts, making navigation effortless.",
  },
  {
    icon: "tradingItemSix",
    title: "Secure and Reliable",
    description:
      "Play with confidence, knowing your data and transactions are securely protected, ensuring a trustworthy environment.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "whyChoose1",
    title: "Expertly Crafted Fantasy Sports Experience",
    description:
      "Our platform is designed by sports enthusiasts for sports enthusiasts, ensuring an immersive and authentic fantasy sports experience.",
  },
  {
    icon: "whyChoose2",
    title: "Robust and Reliable Platform",
    description:
      "Enjoy a seamless gaming experience with our secure, reliable, and user-friendly interface that caters to both beginners and experts.",
  },
  {
    icon: "whyChoose3",
    title: "Exciting Rewards and Competitions",
    description:
      "Compete in various leagues, earn points, and win fantastic prizes, making every match thrilling and rewarding.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Funzone360?",
    content:
      " is a Funzone360fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Funzone360?",
    content:
      "Download the Funzone360 app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
