import React from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import { accordionFullData, journeyData, whyChooseData } from "./mock";
import { NavLink, useNavigate } from "react-router-dom";
import apk from "../../apk/funzone.apk";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="lightbg position-relative">
          <div className="container">
            <div className="hero-sec">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6  my-2">
                  <h1>
                    Join the Ultimate <span>Fantasy Sports </span>Experience!
                  </h1>
                  <p>
                    Welcome to funzone360.com and create your dream team,
                    compete with friends, and win exciting rewards in our
                    thrilling fantasy sports leagues. Start playing now!
                  </p>
                  <div className="mybtn">
                    <Button
                      className="primarybtn"
                      // onClick={() => {
                      //   handleLinkClick("usp");
                      // }}
                    >
                      Get Started
                    </Button>
                    {/* <a href="#contact" className="greenbtn">
                      Know More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="heroimg text-lg-center text-start">
                    <Icon name="Heroimg" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className=" justify-content-center "></div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="darkbg">
          <div className="container">
            <div className="get_app">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-4 col-md-8">
                  <h4>Get the App Now!</h4>
                  <p>
                    Download the Funzone360 app now for a seamless fantasy
                    cricket experience. Join leagues, create teams, and win big!
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 text-md-end text-sm-start">
                  <a
                    type="button"
                    className="whitebtn"
                    href={apk}
                    download="funzone.apk"
                  >
                    Download App Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="usp">
        <div className="start_withmain comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Start <span>your journey </span>with us
                </h4>
                <p>
                  Start your journey with us to explore thrilling fantasy
                  sports, compete, and win exciting rewards. Join now and enjoy!
                </p>
              </div>
            </div>
          </div>

          <div className="start_withsub">
            {journeyData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="start_withbox"
                  // style={{ animationDelay: `${index + 0.1}s` }}
                >
                  <div className="start_withicon">
                    <Icon name={item?.icon} className="img-fluid" />
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container">
        <section id="about">
          <div className="row align-items-center maximum_profirsec">
            <div className="col-lg-5 ">
              <div className="aboutus_img">
                <Icon name="aboutus" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 ">
              <div className="aboutus_text">
                <h4>About US</h4>
                <p>
                  funzone360.com is an online fantasy game designed for the fans
                  who like to showcase their cricket knowledge and analytical
                  skills.
                </p>
                <p>
                  Here, you don a selector’s hat and create a playing XI of your
                  choice – a set of players you think will keep the scorers busy
                  and spectators on the edge of their seats. Here, you trust
                  your skills and create your own fortune – all with the help of
                  on-field players you placed your trust in.
                </p>
                <p>
                  You aren’t a mere spectator anymore. You get to be the manager
                  of your team.
                </p>
                <NavLink to={"/about-us"} className="primarybtn">
                  Know More
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <section id="whychoose" className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Why Choose <span>us ?</span>
                </h4>
                <p>
                  Choose Funzone360 for unmatched fantasy cricket with seamless
                  gameplay, real-time updates, fair play, and exciting rewards.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-5">
              <Icon name="whyChoose" className="img-fluid" />
            </div>
            <div className="col-lg-7">
              {whyChooseData.map((item, index) => (
                <div className="why_choosebox">
                  <div>
                    <div className="whychoose_icon">
                      <Icon name={item?.icon} className="img-fluid" />
                    </div>
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section>
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  FAQ’s About <span>funzone360.com </span>
                </h4>
                <p>
                  Have questions? Our FAQ section provides essential answers to
                  help you get started and enjoy our platform easily.
                </p>
              </div>
            </div>
          </div>
          <div
            className="accordion accordion-flush selfaccord"
            id="accordionFlushExample"
          >
            {accordionFullData?.slice(0, 4)?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={item.id}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}1`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}1`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}1`}
                  className="accordion-collapse collapse"
                  aria-labelledby={item.id}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section id="contact" className="comman_space">
          <div className="contact_main">
            <div className="d-md-flex justify-content-between align-items-center d-block">
              <div className="contact_text">
                <h4 className="mb-0">Contact Us</h4>
                <p>Get in touch with our team to learn more about</p>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Connect With Us
                </Button>
              </div>
              <div className="contact_icon">
                <Icon name="contactushome" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        {/* <div className="categories_swiper">
          <Swiper
            centeredSlides={false}
            spaceBetween={20}
            speed={900}
            parallax
            rewind
            loop
            effect="slide"
            freeMode
            grabCursor={true}
            watchSlidesProgress
            slidesPerView={3}
            breakpoints={{
              1200: { slidesPerView: 4 },
              992: { slidesPerView: 3 },
              768: { slidesPerView: 3 },
              576: { slidesPerView: 2 },
              0: { slidesPerView: 1.5 },
            }}
            autoplay={{
              delay: 19000,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {clientReview?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="category_card ">
                    <Icon name="earn1" className="" />
                    <div className="play_cardtext">
                      <h4>{data?.title}</h4>
                      <p>{data?.productNo}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
