import React from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
  };

  return (
    <>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid mb-3 logo" />
                  </p>
                  <p>
                    Owned and operated by  <Icon name="Compnay" className="company" />,
                    Funzone360.com is an online fantasy game designed for the
                    fans who like to showcase their cricket knowledge and
                    analytical skills.
                  </p>
                </div>
              </div>
              {/*<div className="col-lg-3 col-md-4 col-6 my-2">*/}
              {/*  <div className="link-detail">*/}
              {/*    <h6>Quick Links</h6>*/}
              {/*    <p*/}
              {/*      onClick={() => {*/}
              {/*        handleLinkClick("home");*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Home*/}
              {/*    </p>*/}
              {/*    <p*/}
              {/*      onClick={() => {*/}
              {/*        handleLinkClick("usp");*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Start journey*/}
              {/*    </p>*/}
              {/*    <p*/}
              {/*      onClick={() => {*/}
              {/*        handleLinkClick("whychoose");*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Why Choose Us ?*/}
              {/*    </p>*/}

              {/*    <p>*/}
              {/*      <NavLink to={"/about-us"}>About us</NavLink>*/}
              {/*    </p>*/}

              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/refund-return"}>Refund & Cancellation</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Unique Selling Proposition</h6>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Global Reach
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    iGaming Expertise
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Innovation
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Higher success rates
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="container copy-right">
          <div className="">
            <p>iGaming @2024</p>
          </div>
        </div> */}
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 Funzone360</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
